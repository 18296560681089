import { LanguageCodesEnum } from '@/interfaces/languages.interface'

export const GOOGLE_MAP_ID = '6f8352577a028e8d' // https://mapstyle.withgoogle.com/
export const DESKTOP_BREAKPOINT = 1200
export const TS_IGNORE_RATE_US_KEY = 'IGNORE_RATE_US_GUIDE_'
export const TS_IGNORE_NEW_GUIDE_MESSAGE = 'IGNORE_NEW_GUIDE_MESSAGE_'
export const TOUCH_INTERACTIONS_TO_SHOW_FEEDBACK_DIALOG = 50
export const FALLBACK_FONTS = '"Helvetica Neue", Helvetica, Arial, sans-serif'
export enum ContactTypes {
  PHONE = 'phone',
  WHATS_APP = 'whatsapp',
  WHATS_APP_RAW = 'whatsapp_raw',
  EMAIL = 'email'
}

export enum PlausibleEvents {
  TOPIC_WEBSITE_CLICK = 'Topic Website Click',
  TOPIC_GOOGLE_PLACE_CLICK = 'Topic More Info Click',
  TOPIC_LOCATION_CLICK = 'Topic Location Click',
  TOPIC_TRIP_ADVISOR_CLICK = 'Topic Trip Advisor Click',
  MAP_FILTER_CHANGE = 'Map Filter Change',
  MAP_TAB_CLICK = 'Map Tab Click',
  SEARCH_TAB_CLICK = 'Search Tab Click',
  MAP_PIN_CLICK = 'Map Pin Click',
  PRINT_PDF_CLICK = 'Print PDF Click',
  SHARE_GUIDE_CLICK = 'Share Guide Click',
  CALL_HOST_CLICK = 'Call Host Click',
  EMAIL_HOST_CLICK = 'Email Host Click',
  WHATSAPP_HOST_CLICK = 'WhatsApp Host Click',
  SUBCATEGORY_VIEW = 'Subcategory View',
  CATEGORY_VIEW = 'Category View',
  PWA_LAUNCH = 'PWA Launch',
  GUIDE_LOAD = 'Guide Load',
  GUIDE_FOCUS = 'Guide Focus',
  EXTERNAL_LINK_CLICK = 'External Link Click',
  TOPIC_ENGAGEMENT_TRACKING = 'Topic Engagement Tracking',
  TOPIC_VIEW_TRACKING = 'Topic View Tracking'
}

export enum PlausibleProperties {
  TOPIC_ID = 'topic_id',
  TOPIC_TITLE = 'topic_title',
  MAP_FILTER_TYPE = 'map_filter_type',
  CATEGORY_ID = 'category_id',
  CATEGORY_TITLE = 'category_title',
  SUBCATEGORY_ID = 'subcategory_id',
  SUBCATEGORY_TITLE = 'subcategory_title',
  GUIDE_TOKEN = 'guide_token',
  DESTINATION_ID = 'destination_id',
  ACCOUNT_ID = 'account_id',
  FINGERPRINT = 'fingerprint',
  LINK = 'link',
  ENGAGEMENT_TIME_IN_SECONDS = 'engagement_time_in_seconds'
}

export const DAYJS_LOCALES = {
  [LanguageCodesEnum.FR]: () => import('dayjs/locale/fr'),
  [LanguageCodesEnum.DE]: () => import('dayjs/locale/de'),
  [LanguageCodesEnum.ES]: () => import('dayjs/locale/es'),
  [LanguageCodesEnum.FI]: () => import('dayjs/locale/fi'),
  [LanguageCodesEnum.PT]: () => import('dayjs/locale/pt'),
  [LanguageCodesEnum.IT]: () => import('dayjs/locale/it'),
  [LanguageCodesEnum.NL]: () => import('dayjs/locale/nl'),
  [LanguageCodesEnum.RU]: () => import('dayjs/locale/ru'),
  [LanguageCodesEnum.EL]: () => import('dayjs/locale/el'),
  [LanguageCodesEnum.JA]: () => import('dayjs/locale/ja'),
  [LanguageCodesEnum.CY]: () => import('dayjs/locale/cy'),
  [LanguageCodesEnum.RO]: () => import('dayjs/locale/ro'),
  [LanguageCodesEnum.IND]: () => import('dayjs/locale/id'),
  [LanguageCodesEnum.BG]: () => import('dayjs/locale/bg'),
  [LanguageCodesEnum.ZH]: () => import('dayjs/locale/zh'),
  [LanguageCodesEnum.NO]: () => import('dayjs/locale/nb') // Norwegian Bokmal
}
